import { Flex, BoxProps } from "@chakra-ui/react";
import React from "react";

interface CenteredContainerProps extends BoxProps {
  children: React.ReactNode;
}

const CenteredContainer: React.FC<CenteredContainerProps> = ({
  children,
  ...props
}) => {
  return (
    <Flex
      direction="column"
      paddingX={{ base: "4", md: "6" }}
      paddingY={{ base: "5", md: "5", lg: "5" }}
      boxShadow="lg"
      borderRadius="24px"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default CenteredContainer;