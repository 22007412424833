import React from "react";
import Header from "./components/Header";
import CenteredContainer from "./components/CenteredContainer";
import Footer from "./components/Footer";
import { Text, Box } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

export const darkPurple = "#171717";
export const darkGrey = "#343434";

const Success: React.FC = () => {
  return (
    <div style={{ backgroundColor: darkPurple, height: "100vh" }}>
      <Header />
      <div
        style={{
          paddingTop: "24px",
          paddingBottom: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CenteredContainer
          width={{ base: "100%", md: "500px" }}
          background={darkGrey}
          color="white"
          textAlign="center"
          boxShadow="lg"
          borderRadius="24px"
          paddingX={{ base: "6", md: "10" }}
          paddingY={{ base: "6", md: "10", lg: "10" }}
          marginBottom="24px"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircleIcon boxSize="50px" color="green.500" />
            <Text fontWeight={600} mt={4}>
              Password Reset Successfully
            </Text>
            <Text mt={4}>
            Please return to the app and login with your new password
            </Text>
          </Box>
        </CenteredContainer>
      </div>
      <Footer />
    </div>
  );
};

export default Success;
