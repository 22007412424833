import React from 'react';
import { Image, Divider, Flex, Box } from '@chakra-ui/react';

const Header = () => {

  return (
    <Box width="100%"> 
    <Flex
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      paddingTop="12px"
      paddingBottom="12px"
    >
      <Box flex="1" />
      <Box>
        <Image
          src="/logo.png"
          alt="PocketPod"
          height="42px"
          width="32px"
        />
      </Box>
      <Box flex="1" textAlign="right" />
    </Flex>
    <Divider orientation="horizontal" h={"2px"} background={"#212121"} mt={0}/>
  </Box>
  );
};

export default Header;