import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import Success from "./Success";
import { ChakraProvider } from "@chakra-ui/react";

function App() {
  return (
    <ChakraProvider>
      <Router>
        <div>
          <section>
            <Routes>
              <Route path="/" element={<ResetPassword />} />
              <Route path="/reset" element={<ResetPassword />} />
              <Route path="/success" element={<Success />} />
            </Routes>
          </section>
        </div>
      </Router>
    </ChakraProvider>
  );
}

export default App;
