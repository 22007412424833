import React from "react";
import { Box, Text, Container } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box
      as="footer"
      maxHeight={"500px"}
      width="full"
      pb={8}
    >
      <Container maxWidth="container.xl">
        <Text textAlign="center" fontSize="sm" color="white">
          ©2024 Pocket AI, Inc. All Rights Reserved.{' '}
        </Text>
      </Container>
    </Box>
  );
};

export default Footer;