import React from "react";
import Header from "./components/Header";
import CenteredContainer from "./components/CenteredContainer";
import ResetPasswordForm from "./components/ResetPasswordForm";
import Footer from "./components/Footer";

export const darkPurple = "#171717";
export const darkGrey = "#343434";

const PasswordReset: React.FC = () => {
  return (
    <div style={{ backgroundColor: darkPurple, height: "100vh" }}>
      <Header />
      <div
        style={{
          paddingTop: "24px",
          paddingBottom: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CenteredContainer
          width={{ base: "100%", md: "500px" }}
          background={darkGrey}
          color="white"
          textAlign="center"
          boxShadow="lg"
          borderRadius="24px"
          paddingX={{ base: "6", md: "10" }}
          paddingY={{ base: "6", md: "10", lg: "10" }}
          marginBottom="24px"
        >
          <ResetPasswordForm />
        </CenteredContainer>
      </div>
      <Footer />

    </div>
  );
};

export default PasswordReset;
