import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmPasswordReset } from "firebase/auth";
import { Formik, Form, Field, FieldProps, FormikHelpers } from "formik";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  useToast
} from "@chakra-ui/react";

import { auth } from "../firebase";

export const darkPurple = "#171717";

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [oobCode, setOobCode] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("oobCode");
    setOobCode(code);
  }, [location]);

  const resetPassword = async (newPassword: string) => {
    if (!oobCode) {
      toast({
        title: "Error",
        description: "Invalid or missing action code.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      toast({
        title: "Success",
        description: "Password has been reset successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/success");
    } catch (error: any) {
      console.log(error)
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });

    }
  };

  const validatePasswords = (values: FormValues) => {
    const errors: Partial<FormValues> = {};
    if (!values.newPassword) {
      errors.newPassword = "Password is required";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = "Passwords must match";
    }
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validate={validatePasswords}
        onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
          resetPassword(values.newPassword).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props) => (
          <Form>
            <Field name="newPassword">
              {({ field, form }: FieldProps<string>) => (
                <FormControl
                  isInvalid={!!(form.errors.newPassword && form.touched.newPassword)}
                >
                  <FormLabel color="white" htmlFor="newPassword" marginTop="6px">
                    New Password
                  </FormLabel>
                  <Input
                    {...field}
                    id="newPassword"
                    type="password"
                    placeholder="***********"
                    bgColor="white"
                    color={"black"}
                  />
                  <FormErrorMessage>
                    {form.errors.newPassword?.toString() ?? ""}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="confirmPassword">
              {({ field, form }: FieldProps<string>) => (
                <FormControl isInvalid={!!(form.errors.confirmPassword && form.touched.confirmPassword)} mt={4}>
                  <FormLabel color="white" htmlFor="confirmPassword" marginTop="6px">
                    Confirm Password
                  </FormLabel>
                  <Input
                    {...field}
                    id="confirmPassword"
                    type="password"
                    placeholder="***********"
                    bgColor="white"
                    color={"black"}
                  />
                  <FormErrorMessage>
                    {form.errors.confirmPassword?.toString() ?? ""}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              mt={8}
              bgColor={darkPurple}
              _hover={{ bgColor: darkPurple }}
              width="100%"
              color="white"
              isLoading={props.isSubmitting}
              type="submit"
            >
              Reset Password
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ResetPasswordForm;